import React, { useState, useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF, Bounds } from '@react-three/drei';
import * as THREE from 'three';

function Model() {
  const { scene } = useGLTF('/assets/3Dataphea.glb'); // Pastikan path ke file gltf benar
  const ribbonRef = useRef();
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(new THREE.Vector3());

  useEffect(() => {
    if (scene) {
      // Cari objek ribbon di dalam scene GLTF
      const ribbon = scene.getObjectByName('Ribbon'); // Ganti 'Ribbon' dengan nama yang sesuai dari file GLTF
      if (ribbon) {
        ribbonRef.current = ribbon;
      } else {
        console.warn('Ribbon object not found in GLTF scene.');
      }
    }
  }, [scene]);

  const startDrag = (event) => {
    if (ribbonRef.current) {
      setDragging(true);
      const offset = new THREE.Vector3().subVectors(event.point, ribbonRef.current.position);
      setDragOffset(offset);
    }
  };

  const stopDrag = () => {
    setDragging(false);
  };

  useFrame(({ raycaster }) => {
    if (dragging && ribbonRef.current) {
      const intersects = raycaster.intersectObject(ribbonRef.current);
      if (intersects.length > 0) {
        const newPosition = intersects[0].point.add(dragOffset);
        ribbonRef.current.position.set(newPosition.x, newPosition.y, newPosition.z);
        
        // Simulasi lentur dengan memodifikasi skala (misalnya, lebih lebar jika ditarik)
        ribbonRef.current.scale.set(1, 1, Math.max(1, Math.abs(newPosition.z)));
      }
    }
  });

  return (
    <group>
      <primitive object={scene} scale={1} onPointerDown={startDrag} onPointerUp={stopDrag} onPointerMissed={stopDrag} />
    </group>
  );
}

const Scene = () => {
  return (
    <div className='w-full h-96'>
      <Canvas>
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} />
        
        {/* Menggunakan Bounds untuk otomatis zoom dan fit */}
        <Bounds fit clip observe margin={1}>
          <Model />
        </Bounds>
        
        <OrbitControls />
      </Canvas>
    </div>
  );
};

export default Scene;
