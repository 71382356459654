// src/components/Chatbot.js
import React, { useState } from 'react';
import axios from 'axios';

const API_KEY = process.env.REACT_APP_HF_API_KEY; // Ensure this is set correctly in your .env file

const AI = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (input.trim() === '') return;

    const newMessage = { role: 'user', content: input };
    setMessages([...messages, newMessage]);

    try {
      const response = await axios.post(
        'https://api-inference.huggingface.co/models/gpt2',
        { inputs: input },
        {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const aiMessage = {
        role: 'assistant',
        content: response.data[0].generated_text.trim(), // Adjust if necessary
      };
      setMessages([...messages, newMessage, aiMessage]);
    } catch (error) {
      console.error('Error fetching AI response:', error.response ? error.response.data : error.message);
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error processing your request. Please try again.',
      };
      setMessages([...messages, newMessage, errorMessage]);
    }

    setInput('');
  };

  return (
    <div className='flex justify-center items-center'>
      <div className="w-full mx-auto mt-4 p-6 bg-white rounded-lg shadow-md">
        <p className='text-xl text-emerald-800 font-bold flex justify-center items-center overflof-hidden js-slide-in-from-top pb-4'>Try our first AI chat bot</p>
        <div className="h-64 overflow-y-scroll mb-4 p-4 border border-gray-300 rounded-lg">
        <div className='mb-2  text-left'>
              <div className='inline-block bg-emerald-500 p-2 rounded-t-lg rounded-br-lg'>
                <p>Ask me anything..</p>
              </div>
            </div>
          {messages.map((message, index) => (
            <div key={index} className={`mb-2 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
              <div className={`inline-block p-2 ${message.role === 'user' ? 'bg-blue-500 text-white rounded-t-lg rounded-bl-lg' : 'bg-emerald-500 text-black rounded-t-lg rounded-br-lg max-w-md'}`}>
                {message.content}
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={handleSubmit} className="flex-col justify-center items-center">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
          />
          <button type="submit" className="px-4 py-2 mt-2 bg-blue-500 flex justify-center items-center text-white rounded-lg hover:bg-blue-600">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default AI;
