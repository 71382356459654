import React from 'react';
import Scene from './Scene';

const Hero = () => {

    return (
        <div id='hero' className='relative min-h-screen p-4 bg-emerald-800'>
            <div
                className="absolute inset-0 z-10 bg-emerald-800 mt-20 bg-blend-multiply"
                style={{
                    backgroundImage: "url('https://i.postimg.cc/LsyZcBfJ/vecteezy-wireframe-linear-tech-decorative-background-in-futuristic-7546180.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <div className='relative z-20 grid min-h-screen gap-8 md:grid-cols-2 lg:grid-cols-2'>
                <div className="flex flex-1 -mt-20 sm:mt-20 lg:-mt-2 md:-mt-16 items-center justify-center sm:items-stretch">
                    <div className="flex rounded-xl items-center">
                        <Scene />
                    </div>
                </div>
                <div className='flex px-10 sm:-mt-20  justify-center items-center'>
                    <p className='text-white -mt-52 lg:mt-20 md:mt-20 lg:-ml-32 lg:pr-32 text-xl lg:text-3xl font-bold js-slide-in-from-bottom'>
                        Building Your Digital Future,<br />
                        <span className='text-yellow-400'>One Line of Code</span> at a Time<br />
                        <p className='text-base leading-2 text-justify font-normal'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est reiciendis suscipit dicta, sint eaque, incidunt porro distinctio ullam sed fuga adipisci odit voluptatem animi? Reprehenderit omnis consequatur totam repellat eum.
                        </p>
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Hero;
