import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../src/assets/index.css';
import Home from './Home';
import Splashscreen from './components/Splashscreen';

function App() {

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.classList.contains('js-slide-in-from-left')) {
            entry.target.classList.add('animate-slideInFromLeft');
          } else if (entry.target.classList.contains('js-slide-in-from-right')) {
            entry.target.classList.add('animate-slideInFromRight');
          } else if (entry.target.classList.contains('js-slide-in-from-top')) {
            entry.target.classList.add('animate-slideInFromTop');
          } else if (entry.target.classList.contains('js-slide-in-from-bottom')) {
            entry.target.classList.add('animate-slideInFromBottom');
          }
        } else {
          entry.target.classList.remove('animate-slideInFromLeft', 'animate-slideInFromRight', 'animate-slideInFromTop', 'animate-slideInFromBottom');
        }
      });
    }, observerOptions);

    const targets = document.querySelectorAll('.js-slide-in-from-left, .js-slide-in-from-right, .js-slide-in-from-top, .js-slide-in-from-bottom');
    targets.forEach(target => observer.observe(target));

    return () => {
      targets.forEach(target => observer.unobserve(target));
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Splashscreen />} />
        <Route exact path="/home" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
