import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Footer from './components/Footer';
import AI from './components/AI';
import AboutUs from './components/AboutUs';
import Portfolio from './components/Portfolio';
import Achievement from './components/Achievement';

const Home = () => {
  return (
    <>
      < Navbar />
      <body>
        < Hero />
        < Services/>
        < AboutUs/>
        < Achievement/>
        < AI/>
        < Portfolio/>
      </body>
      <footer>
        < Footer/>
      </footer>
    </>
  );
};

export default Home;
